import { has, isEmpty, get, set } from 'lodash'

/**
 * @namespace mixins/form
 */

import { validationMixin } from 'vuelidate'

export default {
  mixins: [validationMixin],

  methods: {
    /**
     * Will receive the input value from the input updated event and set the validation as touched.
     *
     * @param {*} inputValue - Input value, can be string, number, boolean etc.
     * @param {string} inputName - Property path as defined in your component data.
     *
     * @example handleInputUpdate($event, 'a.b.c')
     */
    handleInputUpdate(inputValue, inputName) {
      inputValue = inputValue === '' ? null : inputValue
      set(this, inputName, inputValue)

      if (this.$v && has(this.$v, inputName)) {
        get(this.$v, inputName).$touch()
      }
    },

    /**
     * Set validation class using validation rules.
     * @param {string} inputName - Property path as defined in your component data.
     * @param {boolean} isFilled - Will add `vd-input-filled` class if true.
     * @returns {array}
     */
    setInputValidationClass(inputName, isFilled) {
      let inputClassesArray = []

      // Add input filled class.
      if (isFilled) {
        inputClassesArray.push('vd-input-filled')
      }

      // Check if input has been changed.
      if (get(this.$v, inputName).$anyDirty) {
        // Check if is required or if it is not empty for setting the error.
        // Optional empty fields should ignore validation.
        if (
          has(this.$v, `${inputName}.$params.required`) ||
          !isEmpty(get(this.$v, `${inputName}.$model`))
        ) {
          // Set class name if error.
          if (get(this.$v, `${inputName}.$error`)) {
            inputClassesArray.push('vd-input-error')
          } else {
            // Set class name if success.
            inputClassesArray.push('vd-input-valid')
          }
        }
      }

      return inputClassesArray
    },
  },
}
